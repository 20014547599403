// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Homepage_container__D-cUz {
  overflow-y: auto; /* Allow vertical scrolling when content overflows */
  overflow-x: auto; /* Allow vertical scrolling when content overflows */
  height: 100vh;
}

.Homepage_container__D-cUz::-webkit-scrollbar {
  width: 0px; /* Set scrollbar width */
}

.Homepage_mUplopad_box__Dqeer {
  display: flex;
}

.Homepage_Digtal_box__luOIF {
  margin: 1vw 0 0 9vw;
}

.Homepage_forecating__Vtouh {
  margin: 1vw 2vw 0 2vw;
}

.Homepage_CategoryInsight__P30Hd {
  margin: 2vw 0 0 9vw;
}

.Homepage_download_report__r75pX {
  margin: 2vw 0 0 2vw;
}

.Homepage_dropdown__3T4ft {
  align-self: center;
  margin-left: auto;
  margin-right: 94px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserHomepage/Homepage.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA,EAAA,oDAAA;EACA,gBAAA,EAAA,oDAAA;EACA,aAAA;AAAJ;;AAIA;EACI,UAAA,EAAA,wBAAA;AADJ;;AAIA;EACI,aAAA;AADJ;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,qBAAA;AAFJ;;AAMA;EACI,mBAAA;AAHJ;;AAMA;EACI,mBAAA;AAHJ;;AAOA;EACQ,kBAAA;EACA,iBAAA;EACA,kBAAA;AAJR","sourcesContent":["\n.container {\n    overflow-y: auto; /* Allow vertical scrolling when content overflows */\n    overflow-x: auto; /* Allow vertical scrolling when content overflows */ \n    height: 100vh;\n    // background: linear-gradient(88deg, #9F93CB 0%, #94C6BA 48%, #9F93CB 100%);\n}\n\n.container::-webkit-scrollbar {\n    width: 0px; /* Set scrollbar width */\n} \n\n.mUplopad_box {\n    display: flex;\n\n}\n\n.Digtal_box {\n    margin: 1vw 0 0 9vw;\n}\n\n.forecating {\n    margin:1vw 2vw 0 2vw;\n\n}\n\n.CategoryInsight {\n    margin: 2vw 0 0 9vw;\n     // margin: 30px 0 0 140px;\n}\n.download_report {\n    margin: 2vw 0 0 2vw;\n     // margin: 30px 0 0 140px;\n}\n\n.dropdown{\n        align-self: center;\n        margin-left: auto;\n        margin-right: 94px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Homepage_container__D-cUz`,
	"mUplopad_box": `Homepage_mUplopad_box__Dqeer`,
	"Digtal_box": `Homepage_Digtal_box__luOIF`,
	"forecating": `Homepage_forecating__Vtouh`,
	"CategoryInsight": `Homepage_CategoryInsight__P30Hd`,
	"download_report": `Homepage_download_report__r75pX`,
	"dropdown": `Homepage_dropdown__3T4ft`
};
export default ___CSS_LOADER_EXPORT___;
