import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../services/Auth/authService";
import { setlocalStorage } from "../../Components/AuthUser/createSession";
import { CheckTokenStatusCode } from "../../utils/helper";

export const SSOLoginApi = createAsyncThunk(
    'auth/SSOLogin',
    async (data, thunkAPI) => {
        try {
            const response = await authService.SSOlogin(data);
            if (response.status === 200) {
                const token = response?.data?.data?.token;
                if (token) {
                    setlocalStorage('token', token);
                }
                return response.data;
            } else {
                throw new Error("Network issue");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const getUserData = createAsyncThunk(
    'auth/getUserData',
    async (data, thunkAPI) => {
        try {
            const response = await authService.UserData(data);
            if (response.status === 200) {
                const user = response.data?.data?.user;
                if (user) {
                    const { username, embeddedUrl, clientName, email_id, embeddedToken, reportId, categoryEmbeddedUrl, categoryEmbeddedToken, categoryReportId } = user;
                    setlocalStorage('username', username);
                    setlocalStorage('embeddedUrl', embeddedUrl);
                    setlocalStorage('clientName', clientName);
                    setlocalStorage('email', email_id);
                    setlocalStorage('embeddedToken', embeddedToken); // Store encrypted token
                    setlocalStorage('reportId', reportId);
                    setlocalStorage('categoryEmbeddedUrl', categoryEmbeddedUrl);
                    setlocalStorage('categoryEmbeddedToken', categoryEmbeddedToken);
                    setlocalStorage('categoryReportId', categoryReportId);
                }
                return response.data;
            } else {
                throw new Error("Network issue");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);

const initialState = {
    SSOloginUserDetails: null,
    getUserDataDetails: null,
    error: null,
    errorGetUserData: null,
    blocking: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetuserDetails: (state) => {
            state.SSOloginUserDetails = null;
            state.getUserDataDetails = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // SSO user login
            .addCase(SSOLoginApi.pending, (state) => {
                state.blocking = true;
            })
            .addCase(SSOLoginApi.fulfilled, (state, action) => {
                state.SSOloginUserDetails = action.payload;
                state.blocking = false;
            })
            .addCase(SSOLoginApi.rejected, (state, action) => {
                state.error = action.payload?.message || 'Failed to perform SSO login';
                state.blocking = false;
            })
            // getUserData
            .addCase(getUserData.pending, (state) => {
                state.blocking = true;
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.getUserDataDetails = action.payload;
                CheckTokenStatusCode(action.payload?.statusCode); // Assuming statusCode is handled correctly in CheckTokenStatusCode
                state.blocking = false;
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.errorGetUserData = action.payload?.message || 'Failed to get user data';
                CheckTokenStatusCode(action.payload?.statusCode); // Assuming statusCode is handled correctly in CheckTokenStatusCode
                state.blocking = false;
            });
    },
});

export default authSlice.reducer;
export const { resetuserDetails } = authSlice.actions;
