import React from 'react'; 
import DigitalShelfDashboard from '../Components/Dashboard/Digitalshelf/DigitalshelfDashboard';
import { getlocalStorage } from '../Components/AuthUser/createSession';
import UserdemoDigitalShelf from './demo/userdemoDigitalShelf';

export default function UserDashboard() {

    const digitalShelfAccess = getlocalStorage('embeddedUrl'); 

    return (
        <>
            {digitalShelfAccess? <DigitalShelfDashboard />:
            <UserdemoDigitalShelf leftnavbar={true} logo={false} back={false} />}
        </>
    )
}
