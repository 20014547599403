// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  width: 100%;
  height: 100vh;
  background-color: #e2e2e2;
  display: flex;
  flex-direction: row;
}

.container-left-view {
  width: 50%;
}

.container-right-view {
  width: 50%;
  min-width: 50%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.1019607843);
  border-radius: 20px 0px 0px 20px;
  padding: 2.9vw;
  box-sizing: border-box;
  overflow: auto;
}
.container-right-view::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.left_img {
  height: 100%;
  width: 102%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/signinPageLayout.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,UAAA;EACA,cAAA;EACA,+CAAA;EACA,qDAAA;EACA,gCAAA;EAEA,cAAA;EACA,sBAAA;EACA,cAAA;AAAJ;AACI;EACI,QAAA;EACA,uBAAA;AACR;;AAIA;EACI,YAAA;EACA,WAAA;AADJ","sourcesContent":[".main-container {\n    width: 100%;\n    height: 100vh;\n    background-color: #e2e2e2;\n    display: flex;\n    flex-direction: row;\n}\n\n.container-left-view {\n    width: 50%;\n}\n\n.container-right-view {\n    width: 50%;\n    min-width: 50%;\n    background: #FFFFFF 0% 0% no-repeat padding-box;\n    box-shadow: 0px 25px 30px #0000001A;\n    border-radius: 20px 0px 0px 20px;\n    // padding: 48px;\n    padding: 2.9vw;\n    box-sizing: border-box;\n    overflow: auto; \n    &::-webkit-scrollbar {\n        width: 0;\n        background: transparent;\n    }\n}\n\n\n.left_img {\n    height: 100%;\n    width: 102%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
