import React from 'react';
import Styles from "./SSO.module.scss";
//import OneClogo from '../../assets/images/OneClogo.png';
import { OneClogoIcon } from '../../icon/IconConstants';

export default function Heading({ className }) {
    return (
        // <div className={`${Styles.logo_oneComerce} ${className}`}>
        //     <img className={Styles.logo_img} src={OneClogo} alt="logo" />
        //     <span>NE&nbsp;commerce</span>
        // </div>
        <>
            <div className={`${Styles.logo_oneComerce} ${className}`}>
                <span className={Styles.icons} dangerouslySetInnerHTML={{ __html: OneClogoIcon }} ></span>
            </div>
        </>
    );
}

