import { useState } from "react"
import Styles from "./menuButtons.module.scss"
import { Link } from "react-router-dom"
import '../../styles/global.scss'; 

export const MenuItem = ({ index, icon, label, subMenu, expand, selected, setSelected, url }) => {

    const [hover, setHover] = useState(false)

    return (
        <Link to={url} className={`${Styles.MenuItem} ${(hover || selected) ? Styles.MenuHover : ''}`} onClick={() => setSelected(index)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}  >
            <div className={` ${selected ? Styles.MenuSelected : ""} ${Styles?.mainMenu}`}>
                <div dangerouslySetInnerHTML={{ __html: icon }} className={`${Styles.MenuIcon} ${selected ? Styles.MenuSelected : ""}`} ></div>
                <div className={`${selected ? Styles.MenuSelected : Styles.MenuLabel}`}>
                    {expand && <div>{label}</div>}
                </div>
            </div>
            {
                selected && expand && <div className={Styles.subMenu}>
                    {
                        subMenu?.map(menu => (
                            <div key={menu.id} className={Styles.subMenuItem}>
                                {menu.label}
                            </div>
                        ))
                    }

                </div>
            }
        </Link>
    )
}