import React from 'react'
import CategoryInsight from '../../Components/GetDemo/demoC_I/CategoryInsight'


export default function UserdemoCategoryInsight({ leftnavbar, logo, back }) {
    return (
        <>
            <CategoryInsight  leftnavbar={leftnavbar} logo={logo} back={back}/>
        </>
    )
}
