import React, { useState } from 'react';
import Styles from '../getDemo.module.scss';
import LeftNavbar from '../../LeftNavBar/LeftNavbar'; 
import forecasting from "../../../assets/images/download.png";
import { playb_hover, playbutton } from '../../../icon/IconConstants';
import { Button } from '../../Button/Button';
import LogoWithName from '../LogoWithName';

export default function CategoryInsight({ leftnavbar, logo, back }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
          {leftnavbar && <LeftNavbar />}
        <LogoWithName clickName ={'categoryinsight'} Name ={'Business Category Insight'} logo={logo} back={back} />
            <div className={Styles.container}>
                <div className={Styles.imgcontainer}>
                    <img className={Styles.forecastingImg} src={forecasting} alt="img"></img>
                    <div className={Styles.playbutton}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                        {isHovered ? <div dangerouslySetInnerHTML={{ __html: playb_hover }} /> :
                            <div dangerouslySetInnerHTML={{ __html: playbutton }} />}
                    </div>
                </div>
                <div className={Styles.headContainer}>
                    <div className={Styles.head_text}>
                        <span >Understand Your Business Better</span>
                    </div>
                    <div className={Styles.Dis_forec}>
                        <span  >
                            Enabling brands with actionable intelligence derived from analyzing data from multiple sources. These insights provide valuable perspectives on sales performance, customer behavior, market trends, and competitor activity. By leveraging data analytics and visualization tools, Business Insights enable informed decision-making and strategic planning to drive business growth and profitability.
                        </span>
                    </div>
                    <div className={Styles.buttn}>
                        <Button onClick={() => { }} name="Request for Opt-In" type="secondary" />
                    </div>
                </div>
            </div>
        </>
    );
}
