import React, { useEffect, useState } from 'react';
import { ABBOTT_DASHBOARD_DOWNLOAD_URL, ADMIN_DASHBOARD_DOWNLOAD_URL, CATEORY_INSIGHT_DASHBOARD_DOWNLOAD_URL, ITC_DASHBOARD_DOWNLOAD_URL } from '../utils/helper';
import { getlocalStorage } from '../Components/AuthUser/createSession';
import ErrorMessages from '../Components/ErrorMessages/ErrorMessages';
import { useParams } from 'react-router-dom';

export default function PowerBiDownloadPage() {

    const User_clientName = getlocalStorage('clientName');
    const User_email = getlocalStorage('email');
    const [errorMessage, setErrorMessage] = useState('');

    let apiEndpoint;
    const dashboardType = useParams('dashboardType');

    useEffect(() => {
        if (!User_clientName || !User_email || !dashboardType) {
            setErrorMessage('Invalid user information or dashboard type.');
            return;
        }
        switch (dashboardType?.dashboardType) {
            case 'category_insight':
                switch (User_clientName) {
                    case 'ITC':
                    case 'abbott':
                    case 'Admin':
                        apiEndpoint = `${CATEORY_INSIGHT_DASHBOARD_DOWNLOAD_URL}${User_email}`;
                        break;
                    default:
                        setErrorMessage('Unknown client name.');
                        return;
                }
                break;
            case 'digital_shelf':
                switch (User_clientName) {
                    case 'ITC':
                        apiEndpoint = `${ITC_DASHBOARD_DOWNLOAD_URL}${User_email}`;
                        break;
                    case 'abbott':
                        apiEndpoint = `${ABBOTT_DASHBOARD_DOWNLOAD_URL}${User_email}`;
                        break;
                    case 'Admin':
                        apiEndpoint = `${ADMIN_DASHBOARD_DOWNLOAD_URL}${User_email}`;
                        break;
                    default:
                        setErrorMessage('Unknown client name.');
                        return;
                }
                break;
            default:
                setErrorMessage('Unknown dashboard type.');
                return;
        }
    }, [dashboardType, User_clientName, User_email]);

    useEffect(() => {
        if (!apiEndpoint) {
            if (errorMessage) {
                const closeTab = setTimeout(() => {
                    window.close();
                }, 5000);
                return () => clearTimeout(closeTab);
            }
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(apiEndpoint);
                // Handle your response here if needed
                console.log('API call successful');
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrorMessage('Error fetching data. Please try again later.');
            }
        };

        fetchData();

        const closeTab = setTimeout(() => {
            window.close();
        }, 3000);
        return () => clearTimeout(closeTab);
    }, [apiEndpoint, errorMessage]);

    return (
        <div className='align_center'>
            {errorMessage ? (
                <ErrorMessages errors={[errorMessage]} />
            ) : (
                <p className='align_center_text'>{`Your ${dashboardType?.dashboardType} dashboard has been downloaded. You will receive an email at ${User_email} in a few minutes.`}</p>
            )}
        </div>
    );
}
