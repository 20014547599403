// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_d_flex__JrJwT {
  display: flex;
}

.Header_icon_leftnavbar__-dqaO {
  width: 2vw !important;
  height: 3vh !important;
}

.Header_align_center__OXWDG {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Header_align_center_text__qD7HS {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Quicksand";
}

.Header_warningimg__pUonr {
  width: 24px;
  height: 24px;
}

.Header_container__0OWAr {
  display: flex;
  justify-content: space-between;
  height: 110px;
}

.Header_user_text__rB86p {
  font: normal normal bold 2vw/33px "Quicksand";
  letter-spacing: 0.54px;
  color: #000000;
  align-self: center;
}

.Header_logo_img__rAsN7 {
  width: 5vw;
  height: 5vw;
  margin: 20px 0 0 8vw;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss","webpack://./src/Components/Header/Header.module.scss"],"names":[],"mappings":"AAaA;EACI,aAAA;ACZJ;;ADeA;EACI,qBAAA;EACA,sBAAA;ACZJ;;ADgBA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;ACbJ;;ADiBA;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,wBAnCQ;ACqBZ;;ADiBA;EACI,WAAA;EACA,YAAA;ACdJ;;AApBA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;AAuBJ;;AApBA;EACI,6CAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;AAuBJ;;AApBA;EAII,UAAA;EACA,WAAA;EACA,oBAAA;AAoBJ","sourcesContent":["//global.scss\n$body-font: 'Quicksand';\n$font-Din: 'DIN';\n$body-font-sand: 'Quicksand';\n$black-Background: #000000;\n$blue-background: #cbe3f4;\n$red-color: #ff1a11;\n$light-blue-color: #F5FBFF;\n$light-shade-gray: #D4D4D4;\n$medium-shade-gray: #A3A3A3;\n$white-color: #FFFFFF;\n$Sky-Blue: #63a3ce;\n\n.d_flex {\n    display: flex;\n}\n\n.icon_leftnavbar{\n    width: 2vw !important;\n    height: 3vh !important;\n}\n\n \n.align_center {\n    display: flex;\n    justify-content: center; // Horizontally center the button\n    align-items: center; // Vertically center the button\n    height: 100vh;  \n}\n\n \n.align_center_text {\n    padding: 10px 20px;   \n    border: none;  \n    border-radius: 5px;  \n    font-size: 16px;  \n    font-family: $body-font; \n}\n\n.warningimg{\n    width: 24px;\n    height: 24px;\n}","@import '../../styles/global.scss';\n\n$headr_font: $body-font ;\n\n\n \n\n.container {\n    display: flex;\n    justify-content: space-between;\n    height: 110px;\n}\n\n.user_text {\n    font: normal normal bold 2vw / 33px \"Quicksand\";\n    letter-spacing: 0.54px;\n    color: #000000;\n    align-self: center;\n}\n\n.logo_img {\n    // width: 81px;\n    // height: 84px;\n    // margin: 20px 0 0 130px;\n    width: 5vw;\n    height: 5vw;\n    margin: 20px 0 0 8vw;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"d_flex": `Header_d_flex__JrJwT`,
	"icon_leftnavbar": `Header_icon_leftnavbar__-dqaO`,
	"align_center": `Header_align_center__OXWDG`,
	"align_center_text": `Header_align_center_text__qD7HS`,
	"warningimg": `Header_warningimg__pUonr`,
	"container": `Header_container__0OWAr`,
	"user_text": `Header_user_text__rB86p`,
	"logo_img": `Header_logo_img__rAsN7`
};
export default ___CSS_LOADER_EXPORT___;
