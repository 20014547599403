import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { CheckTokenStatusCode } from "../../utils/helper";  
import clientDataService from "../../services/ClientData/clientDataService";

 
export const getClientData = createAsyncThunk(
    'clientData/getClientData',
    async (data, thunkAPI) => {
        try {
            const response = await clientDataService.ClientData(data);
            if (response.status === 200) { 
                
                return response.data;
            } else {
                throw new Error("Network issue");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);

const initialState = {
    getClientDataDetails: null,
    error: null,
    clientblocking: false,
};

const clientDataSlice = createSlice({
    name: 'clientData',
    initialState,
    reducers: {
        resetClientDataDetails: (state) => {
            state.getClientDataDetails = null;
        },
    },
    extraReducers: (builder) => {
        builder
 
            // getClientData
            .addCase(getClientData.pending, (state) => {
                state.clientblocking = true;
            })
            .addCase(getClientData.fulfilled, (state, action) => {
                state.getClientDataDetails = action.payload;
                CheckTokenStatusCode(action.payload?.statusCode); // Assuming statusCode is handled correctly in CheckTokenStatusCode
                state.clientblocking = false;
            })
            .addCase(getClientData.rejected, (state, action) => {
                state.errorgetClientData = action.payload?.message || 'Failed to get user data';
                CheckTokenStatusCode(action.payload?.statusCode); // Assuming statusCode is handled correctly in CheckTokenStatusCode
                state.clientblocking = false;
            });
    },
});

export default clientDataSlice.reducer;
export const { resetClientDataDetails } = clientDataSlice.actions;
