import React from 'react'; 
import Styles from './getDemo.module.scss';
import { useNavigate } from 'react-router-dom';
import Digital_shelf from "../../assets/images/Digital_shelf.png";
import Fore_casting from "../../assets/images/Fore_casting.jpg"; 
import Download from "../../assets/images/C_I.png";  
import LogoWithName from './LogoWithName';
import CustomCard from '../CustomCard/CustomCard';


export default function GetDemo({ logo, back }) { 

    const navigate = useNavigate();
 
    const handleButtonClick = (value) => {
        // setdigital(true);
        if (value == 'digitalshelf') {
            navigate('/demo/digitalshelf')
        }
        if (value == 'Forecasting') {
            navigate('/demo/forecasting')
        }
        if (value == 'categoryinsight') {
            navigate('/demo/categoryinsight')
        }
        if (value == 'demo') {
            navigate('/signin')
        }
    };
    return (
        <>
        <LogoWithName clickName ={'demo'} Name ={'Get Demo'} logo={logo} back={back}/>
            <div>
                <div className={Styles.mUplopad_box}>
                    <div className={Styles.demoDigtalBox}>
                        <CustomCard
                            title={'Digital Shelf'}
                            discrip_p={'Your destination for continuously tracking and analyzing the online presence and performance of products across different eCommerce Platforms. It involves monitoring product listings, pricing, availability, customer reviews, and competitor activity to ensure optimal visibility, positioning, and competitiveness in the online marketplace.'}
                            buttonName={'Get Demo'}
                            onClick={() => handleButtonClick('digitalshelf')}
                            imgUrl={Digital_shelf}
                        // checked={true}
                        />
                    </div>
                    <div className={Styles.demoForecating}>
                        <CustomCard
                            title={'Forecasting'}
                            discrip_p={'We help brands anticipate demand, optimize inventory management, allocate resources effectively, and plan marketing strategies. By providing foresight into potential opportunities and challenges, the Forecasting Module enables businesses to make proactive decisions and stay ahead in a dynamic marketplace'}
                            buttonName={'Get Demo'}
                            onClick={() => handleButtonClick('Forecasting')}
                            imgUrl={Fore_casting}
                        />
                    </div>
                </div>
                <div className={Styles.mUplopad_box}>
                    <div className={Styles.demoCategoryInsight}>
                        <CustomCard
                            title={'Category Insights'}
                            discrip_p={'Enabling brands with actionable intelligence derived from analyzing data from multiple sources. These insights provide valuable perspectives on sales performance, customer behavior, market trends, and competitor activity. By leveraging data analytics and visualization tools, Business Insights enable informed decision-making and strategic planning to drive business growth and profitability.'}
                            buttonName={'Get Demo'}
                            onClick={() => handleButtonClick('categoryinsight')}
                            imgUrl={Download}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
