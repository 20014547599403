// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Homepage_container__D-cUz {
  overflow-y: auto; /* Allow vertical scrolling when content overflows */
  overflow-x: auto; /* Allow vertical scrolling when content overflows */
  height: 100vh;
}
.Homepage_container__D-cUz .Homepage_container__D-cUz::-webkit-scrollbar {
  width: 0px; /* Set scrollbar width */
}
.Homepage_container__D-cUz .Homepage_mUplopad_box__Dqeer {
  display: flex;
}
.Homepage_container__D-cUz .Homepage_Digtal_box__luOIF {
  margin: 1vw 0 0 9vw;
}
.Homepage_container__D-cUz .Homepage_forecating__Vtouh {
  margin: 1vw 2vw 0 2vw;
}
.Homepage_container__D-cUz .Homepage_CategoryInsight__P30Hd {
  margin: 2vw 0 2vw 9vw;
}
.Homepage_container__D-cUz .Homepage_download_report__r75pX {
  margin: 2vw 0 0 2vw;
}
.Homepage_container__D-cUz .Homepage_headerContainer__K4MKM {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.Homepage_container__D-cUz .Homepage_Dropdown__5NSVE {
  margin-right: 7.5vw;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserHomepage/Homepage.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA,EAAA,oDAAA;EACA,gBAAA,EAAA,oDAAA;EACA,aAAA;AAAJ;AAIA;EACI,UAAA,EAAA,wBAAA;AAFJ;AAKA;EACI,aAAA;AAHJ;AAOA;EACI,mBAAA;AALJ;AAQA;EACI,qBAAA;AANJ;AAUA;EACI,qBAAA;AARJ;AAWA;EACI,mBAAA;AATJ;AAaA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,mBAAA;AAXJ;AAaA;EACI,mBAAA;AAXJ","sourcesContent":["\n.container {\n    overflow-y: auto; /* Allow vertical scrolling when content overflows */\n    overflow-x: auto; /* Allow vertical scrolling when content overflows */ \n    height: 100vh;\n    // background: linear-gradient(88deg, #9F93CB 0%, #94C6BA 48%, #9F93CB 100%);\n\n\n.container::-webkit-scrollbar {\n    width: 0px; /* Set scrollbar width */\n} \n\n.mUplopad_box {\n    display: flex;\n\n}\n\n.Digtal_box {\n    margin: 1vw 0 0 9vw;\n}\n\n.forecating {\n    margin:1vw 2vw 0 2vw;\n\n}\n\n.CategoryInsight {\n    margin: 2vw 0 2vw 9vw;\n     // margin: 30px 0 0 140px;\n}\n.download_report {\n    margin: 2vw 0 0 2vw;\n     // margin: 30px 0 0 140px;\n}\n\n.headerContainer {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    align-items: center;\n}\n.Dropdown{\n    margin-right: 7.5vw;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Homepage_container__D-cUz`,
	"mUplopad_box": `Homepage_mUplopad_box__Dqeer`,
	"Digtal_box": `Homepage_Digtal_box__luOIF`,
	"forecating": `Homepage_forecating__Vtouh`,
	"CategoryInsight": `Homepage_CategoryInsight__P30Hd`,
	"download_report": `Homepage_download_report__r75pX`,
	"headerContainer": `Homepage_headerContainer__K4MKM`,
	"Dropdown": `Homepage_Dropdown__5NSVE`
};
export default ___CSS_LOADER_EXPORT___;
