import { useEffect } from 'react';
import { Button } from '../Button/Button';
import Styles from "./SSO.module.scss";
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearStorage, getlocalStorage } from '../AuthUser/createSession';
import { SSO_SIGNIN_URL } from '../../utils/helper';
import { clearMessage, setMessage } from '../../slices/message/messageSlice';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import Heading from './Heading';

const SSOLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const message = useSelector((state) => state.message.content);
    const tokenStatus = getlocalStorage('checkToken'); 

    const { t } = useTranslation();

    useEffect(() => {
        if(tokenStatus){
            dispatch(setMessage('Token expired, please login again.'))
        }
        clearStorage()
    }, []);

    useEffect(() => {
        // Set a timeout to clear the message after 5 seconds (5000 milliseconds)
        const timer = setTimeout(() => {
            dispatch(clearMessage());
        }, 5000);

        // Clear the timeout if the component unmounts
        return () => {
            clearTimeout(timer);
        };
    }, [dispatch]);

    const handlesubmit = () => {
        window.location.href = SSO_SIGNIN_URL
    };
    function handledemo() {
        navigate('/demo')
    };

    return (
        <div className={Styles.container}>
            <Heading />
            <label className={Styles.Disp}>
                {t('SignDiscription')}
            </label>
            <div className={Styles.title}>Access your account securely with a single sign-on.</div>
            <Button onClick={handlesubmit} name={"Sign In with SSO"} type="secondary" />
            <ErrorMessages errors={[message]} />
            <div className={Styles.divider}></div>
            <div className={Styles.getDemoBigText}>Unlock Your Free Demo</div>
            <div className={Styles.getDemoSmallText}>Discover How Our Tool Can Elevate Your Business. Get Started Now!</div>
            <Button onClick={handledemo} name="Get Demo" type="secondary" />
        </div>
    );
};

export default SSOLogin;
