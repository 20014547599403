// ProtectedRoute.js
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getlocalStorage } from './createSession';
import { useFetchUserDataPeriodically } from '../../utils/utils';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [display, setDisplay] = useState(false);
    const token = getlocalStorage("token");
    useEffect(() => {
        if (token) {
            setDisplay(true)
        }
        else {
            navigate("/signin")
        }
    }, [token]);
    
    const fetchUserDataInterval = useFetchUserDataPeriodically();

    useEffect(() => {
        const interval = fetchUserDataInterval();
        return () => clearInterval(interval);
    }, [fetchUserDataInterval]);

    return (
        <>
            {display && children}
        </>
    )
}
export default ProtectedRoute;
