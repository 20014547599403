import Styles from "./Button.module.scss";

export const Button = (props) => {
    return (
        <button
            onClick={() => {
                props?.onClick && props?.onClick();
            }}
            className={
                props?.type === "secondary"
                    ? Styles.buttonSecondary
                    : props?.type === "third"
                        ? Styles.buttonThird
                        : Styles.button // default to Styles.button for other cases
            }
        >
            {props.name}
        </button>
    );
};
