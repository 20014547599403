import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    content: '',
  },
  reducers: {
    setMessage: (state, action) => {
      state.content = action.payload;
    },
    clearMessage: (state) => {
      state.content = '';
    },
  },
});

export const { setMessage, clearMessage } = messageSlice.actions;
export default messageSlice.reducer;
