// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_d_flex__1Nj2\\+ {
  display: flex;
}

.Dashboard_icon_leftnavbar__J7a-U {
  width: 2vw !important;
  height: 3vh !important;
}

.Dashboard_align_center__vOfgA {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Dashboard_align_center_text__FOeFp {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Quicksand";
}

.Dashboard_warningimg__2UWXI {
  width: 24px;
  height: 24px;
}

.Dashboard_container_wrapper__oMvUn {
  width: 100vw;
  height: 100vh;
}
.Dashboard_container_wrapper__oMvUn .Dashboard_powerbi_report__-BiLC {
  height: 100%;
  margin: 0px 0px 0px 90px;
  border: none !important;
}

.Dashboard_container_wrapper__oMvUn {
  overflow-y: auto;
  overflow-x: auto;
}

/* Custom scrollbar styles */
.Dashboard_container_wrapper__oMvUn::-webkit-scrollbar {
  width: 0px; /* Set scrollbar width */
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss","webpack://./src/Components/Dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAaA;EACI,aAAA;ACZJ;;ADeA;EACI,qBAAA;EACA,sBAAA;ACZJ;;ADgBA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;ACbJ;;ADiBA;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,wBAnCQ;ACqBZ;;ADiBA;EACI,WAAA;EACA,YAAA;ACdJ;;AAvBA;EACI,YAAA;EACA,aAAA;AA0BJ;AAxBI;EACI,YAAA;EACA,wBAAA;EACA,uBAAA;AA0BR;;AAtBA;EACI,gBAAA;EACA,gBAAA;AAyBJ;;AAtBA,4BAAA;AACA;EACI,UAAA,EAAA,wBAAA;AAyBJ","sourcesContent":["//global.scss\n$body-font: 'Quicksand';\n$font-Din: 'DIN';\n$body-font-sand: 'Quicksand';\n$black-Background: #000000;\n$blue-background: #cbe3f4;\n$red-color: #ff1a11;\n$light-blue-color: #F5FBFF;\n$light-shade-gray: #D4D4D4;\n$medium-shade-gray: #A3A3A3;\n$white-color: #FFFFFF;\n$Sky-Blue: #63a3ce;\n\n.d_flex {\n    display: flex;\n}\n\n.icon_leftnavbar{\n    width: 2vw !important;\n    height: 3vh !important;\n}\n\n \n.align_center {\n    display: flex;\n    justify-content: center; // Horizontally center the button\n    align-items: center; // Vertically center the button\n    height: 100vh;  \n}\n\n \n.align_center_text {\n    padding: 10px 20px;   \n    border: none;  \n    border-radius: 5px;  \n    font-size: 16px;  \n    font-family: $body-font; \n}\n\n.warningimg{\n    width: 24px;\n    height: 24px;\n}","@import '../../styles/global.scss';  \n\n$dashboard_font: $body-font;  \n\n.container_wrapper {\n    width: 100vw;\n    height: 100vh; \n    \n    .powerbi_report {\n        height: 100%;\n        margin: 0px 0px 0px 90px;\n        border: none !important;\n    }\n}\n\n.container_wrapper {\n    overflow-y: auto; \n    overflow-x: auto;  \n}\n\n/* Custom scrollbar styles */\n.container_wrapper::-webkit-scrollbar {\n    width: 0px; /* Set scrollbar width */\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"d_flex": `Dashboard_d_flex__1Nj2+`,
	"icon_leftnavbar": `Dashboard_icon_leftnavbar__J7a-U`,
	"align_center": `Dashboard_align_center__vOfgA`,
	"align_center_text": `Dashboard_align_center_text__FOeFp`,
	"warningimg": `Dashboard_warningimg__2UWXI`,
	"container_wrapper": `Dashboard_container_wrapper__oMvUn`,
	"powerbi_report": `Dashboard_powerbi_report__-BiLC`
};
export default ___CSS_LOADER_EXPORT___;
