import React from 'react'
import Heading from '../SSO/Heading'
import Styles from './getDemo.module.scss';
import { backArrow } from '../../icon/IconConstants';
import { useNavigate } from 'react-router-dom';

export default function LogoWithName({ clickName, Name, back, logo }) {
    const navigate = useNavigate();

    const handleButtonClick = (value) => {
        // setdigital(true);
        if (value == 'digitalshelf') {
            navigate('/demo')
        }
        if (value == 'Forecasting') {
            navigate('/demo')
        }
        if (value == 'categoryinsight') {
            navigate('/demo')
        }
        if (value == 'demo') {
            navigate('/signin')
        }
    };
    return (
        <div className={Styles.logo_with_heading}>
            <div className={Styles.leftContent}>
                {logo && <Heading className={Styles.logo_text} />}
                <span className={Styles.lableheading}>{Name}</span>
            </div>
            {back && <div className={Styles.backContainer}>
                <label onClick={() => handleButtonClick(clickName)} className={Styles.back_arrow}>
                    <span dangerouslySetInnerHTML={{ __html: backArrow }}></span>
                </label>
                <span className={Styles.backText}>&nbsp;Back</span>
            </div>}
        </div>
    )
}
