import React from 'react';
import Styles from './CustomCard.module.scss';
import { Button } from '../Button/Button';
import { checkmark } from '../../icon/IconConstants';

const CustomCard = ({ imgUrl, title, discrip_p, buttonName, onClick, checked }) => {

  return (
    <>
      <div className={Styles.DigtalShelf_box}>
        <div className={Styles.box_view}>
          <div className={Styles.image_box}>
            <img className={Styles.img_circle} src={imgUrl} alt='img' />
          </div>
          <div className={Styles.Box_details}>
            <div className={Styles.rightmark_title}>
              <p className={Styles.title}>{title}</p>
              {checked ? <span className={Styles.icon} dangerouslySetInnerHTML={{ __html: checkmark }}></span> : ''}
            </div>
            <label className={Styles.lable_text}>{discrip_p}</label>
            <div className={Styles.button_adj}>
              <Button onClick={onClick} name={buttonName} type="secondary" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomCard;