// Layout.js
import React from 'react';
import LeftNavbar from '../LeftNavBar/LeftNavbar';
import Header from '../Header/Header';

const Layout = ({ Leftnavbar, header, children }) => {
  return (
    <>
      {Leftnavbar ? <LeftNavbar /> : ''}
      {header ? <Header /> : ''}
      {children}
    </>
  );
};

export default Layout;
