import "./signinPageLayout.scss"
import layoutleft from "../../assets/images/Layoutleft_img2.png";

const SigninPageLayout = (props) => {
    return <div className="main-container">
        <div className="container-left-view">
            <img className='left_img' src={layoutleft} alt="layoutleftimg"></img>
        </div>
        <div className="container-right-view">
            {props?.children}
        </div>
    </div>
}

export default SigninPageLayout; 