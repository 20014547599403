import React from 'react';
import Header from '../Header/Header';
import Styles from './Homepage.module.scss';
import { useNavigate } from 'react-router-dom';
import Digital_shelf from "../../assets/images/Digital_shelf.png";
import Fore_casting from "../../assets/images/Fore_casting.jpg";
import Download from "../../assets/images/C_I.png";
import Layout from '../Layout/Layout';
import '../../styles/global.scss';
import CustomCard from '../CustomCard/CustomCard';


export default function Homepage() {
    const navigate = useNavigate();

    const handleButtonClick = (value) => {
        if (value === 'digitalshelf') {
            navigate('/digitalshelf')
        }
        if (value === 'Forecasting') {
            navigate('/forecasting')
        }
        if (value === 'categoryinsight') {
            navigate('/categoryinsight')
        }
    };

    return (
        <>
            <div className={Styles.container}>
                <Layout Leftnavbar={true}>
                    <div className="d_flex">
                        <Header logo={true} />
                    </div>
                    <div className={Styles.mUplopad_box}>
                        <div className={Styles.Digtal_box}>
                            <CustomCard
                                title={'Digital Shelf'}
                                discrip_p={'Your destination for continuously tracking and analyzing the online presence and performance of products across different eCommerce Platforms. It involves monitoring product listings, pricing, availability, customer reviews, and competitor activity to ensure optimal visibility, positioning, and competitiveness in the online marketplace.'}
                                buttonName={'View More'}
                                onClick={() => handleButtonClick('digitalshelf')}
                                imgUrl={Digital_shelf}
                                checked={true}
                            />
                        </div>
                        <div className={Styles.forecating}>
                            <CustomCard
                                title={'Forecasting'}
                                discrip_p={'We help brands anticipate demand, optimize inventory management, allocate resources effectively, and plan marketing strategies. By providing foresight into potential opportunities and challenges, the Forecasting Module enables businesses to make proactive decisions and stay ahead in a dynamic marketplace'}
                                buttonName={'Get Demo'}
                                onClick={() => handleButtonClick('Forecasting')}
                                imgUrl={Fore_casting}
                            />
                        </div>
                    </div>
                    <div className={Styles.mUplopad_box}>
                        <div className={Styles.CategoryInsight}>
                            <CustomCard
                                title={'Category Insight'}
                                discrip_p={'Enabling brands with actionable intelligence derived from analyzing data from multiple sources. These insights provide valuable perspectives on sales performance, customer behavior, market trends, and competitor activity. By leveraging data analytics and visualization tools, Business Insights enable informed decision-making and strategic planning to drive business growth and profitability.'}
                                buttonName={'View More'}
                                onClick={() => handleButtonClick('categoryinsight')}
                                imgUrl={Download}
                                checked={true}

                            />
                        </div>
                        {/* <div className={Styles.download_report}>
                            <CustomCard
                                title={'Download Report'}

                                discrip_p={'Your destination for scheduled & generated reports in CSV/PDF format.'}
                                buttonName={'View More'}
                                // onClick={handleButtonClick}
                                imgUrl={Download_reports}

                            />
                        </div> */}
                    </div>
                </Layout>
            </div>
        </>
    )
}
