import React from 'react';
import Homepage from '../Components/UserHomepage/Homepage';

export default function Userhomepage() {
 
    return (
        <>
        <Homepage />
        </>
    )
}
