import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Styles from '../Dashboard.module.scss';

const PowerBIEmbedComponent = ({ reportUrl, reportId, accessToken, tokenValid }) => {
  return (
    <div className={Styles.container_wrapper}>
      {reportUrl && tokenValid ? (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportId,
            embedUrl: reportUrl,
            accessToken: accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                  navContentPaneEnabled: false,
                },
              },
              layoutType: models.LayoutType.MobilePortrait,
              fullscreen: true,
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              ['loaded', () => console.log('Report loaded')],
              ['rendered', () => console.log('Report rendered')],
              ['error', (event) => console.error(event.detail)],
              ['pageChanged', (event) => console.log('Page changed:', event)],
            ])
          }
          cssClassName={Styles.powerbi_report}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      ) : (
        <div className='align_center'>
          {!reportUrl && !tokenValid && (
            <p className='align_center_text'>Power BI report URL is not available and Token is not valid.</p>
          )}
          {!reportUrl && tokenValid && (
            <p className='align_center_text'>Power BI report URL is not available.</p>
          )}
          {reportUrl && !tokenValid && (
            <p className='align_center_text'>Token is not valid.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PowerBIEmbedComponent;
