import React, { useEffect, useState } from 'react';
import './CustomDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import useOutsideClick from '../../utils/OutsideClick';

const CustomDropdown = ({ options, onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || '');
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (option) => {
    setValue(option);
    setIsOpen(false);
    onChange(option);
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle the dropdown open/close on click
  };

  const dropdownRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (options && defaultValue) {
      const defaultOption = options.find(option => option === defaultValue);
      if (defaultOption) {
        setValue(defaultOption);
      }
    }
  },[defaultValue,options]);

  return (
    <>
      <div className="custom-dropdown" ref={dropdownRef}>
        <h1 className='heading'>Select Client</h1>
        <div className="custom-dropdown__selected-option" onClick={handleToggleDropdown}>
          <span className="custom-dropdown__text">{value ? value : 'Select'}</span>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} style={{ color: '#707070' }} />
        </div>
        {isOpen && (
          <div className="custom-dropdown__options">
            {options?.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelectOption(option)}
                className="custom-dropdown__option"
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDropdown;
