import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ProtectedRoute from './Components/AuthUser/ProtectedRoute';
import UserSignin from './pages/userSignin';
import DemoPage from './pages/demoPage';
import UserdemoDigitalShelf from './pages/demo/userdemoDigitalShelf';
import UserdemoCategoryInsight from './pages/demo/userdemoCategoryInsight';
import Userdemoforecasting from './pages/demo/userdemoForeCasting';
import DecryptComponent from './Components/authValidation/decryptData';
import Userhomepage from './pages/userHomePage';
import UserDashboard from './pages/userDashboard';
import PowerBiDownloadPage from './pages/powerBiDownloadPage';
import PageNotFound from './pages/pageNotFound';
import CategoryIDashbord from './pages/userCategoryIDashbord';

function App() {
  const NavigateToSignIn = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/signin");
    }, [navigate]);

    return null;
  }

  return (
    <Routes>
      {/* ProtectedRoutes */}
      <Route path="/home" element={<ProtectedRoute> <Userhomepage /> </ProtectedRoute>} />
      <Route path="/download/:dashboardType" element={<ProtectedRoute><PowerBiDownloadPage /></ProtectedRoute>} />
      <Route path="/digitalshelf" element={<ProtectedRoute> <UserDashboard />    </ProtectedRoute>} />
      <Route path="/forecasting" element={<ProtectedRoute>  <Userdemoforecasting leftnavbar={true} logo={false} back={false} />  </ProtectedRoute>} />
      <Route path="/categoryinsight" element={<ProtectedRoute> <CategoryIDashbord />  </ProtectedRoute>} />

      <Route path="/redirect" element={<DecryptComponent />} />
      <Route path="/demo" element={<DemoPage leftnavbar={false} logo={true} back={true} />} />
      <Route path="/demo/forecasting" element={<Userdemoforecasting leftnavbar={false} logo={true} back={true} />} />
      <Route path="/demo/categoryinsight" element={<UserdemoCategoryInsight leftnavbar={false} logo={true} back={true} />} />
      <Route path="/demo/digitalshelf" element={<UserdemoDigitalShelf logo={true} back={true} />} />
      <Route path="/signin" element={<UserSignin />} />
      <Route path="/" element={<NavigateToSignIn />} />
      <Route path='*' element={<div><PageNotFound /></div>}></Route>
    </Routes>
  );
}
export default App;