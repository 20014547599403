import React from 'react'
import CategoryInsight from '../Components/Dashboard/CategoryInsight/CategoryInsight'

export default function CategoryIDashbord() {
  return (
    <>
      <CategoryInsight />
    </>
  )
}
