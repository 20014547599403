import { combineReducers } from 'redux';
import authReducer from './auth/authSlice';  
import messageReducer from './message/messageSlice';
import clientDataReducer from './clientData/clientDataSlice';
const rootReducer = combineReducers({
    auth: authReducer,  
    message: messageReducer,
    clientData: clientDataReducer,

});

export default rootReducer;