import { useDispatch } from 'react-redux';
import { getUserData } from '../slices/auth/authSlice';

export const useFetchUserData = () => {
  const dispatch = useDispatch();

  const fetchUserData = () => {
    return dispatch(getUserData());
  };

  return fetchUserData;
};

export const useFetchUserDataPeriodically = () => {
  const fetchUserData = useFetchUserData();

  const fetchUserDataInterval = () => {
    const interval = setInterval(() => {
      fetchUserData();
    }, 47 * 60 * 1000); // set minutes to interval

    return interval;
  };

  return fetchUserDataInterval;
};