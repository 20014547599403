const OAUTH_TOKEN_KEY = 'token';

// Set the access token in session storage
const setSessionStorage = (name ,accessToken) => {
    sessionStorage.setItem(name, accessToken);
};

// Get the access token from session storage
const getSessionStorage = (key) => {
    return sessionStorage.getItem(key);
};
// Set the access token in session storage
const setlocalStorage = (name ,accessToken) => {
    localStorage.setItem(name, accessToken);
};

// Get the access token from session storage
const getlocalStorage = (key) => {
    return localStorage.getItem(key);
};


// Check if access token exists in session storage
const hasAccessToken = () => {
    return !!sessionStorage.getItem(OAUTH_TOKEN_KEY);
};

// Clear both session and local storage
const clearStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
};

// Function to get the username from local storage
const getUserName = () => {
    return getlocalStorage('username');
};

export {
    setSessionStorage,
    getSessionStorage,
    hasAccessToken,
    clearStorage,
    getUserName,
    setlocalStorage,
    getlocalStorage,
};
