import React from 'react';
import Styles from "./errorMessages.module.scss";

const ErrorMessages = ({ errors }) => {
  return (
    <>
      {errors?.length > 0 && (
        <div className={Styles.errorText}>
          <span>
            {errors?.map((error, index) => (
              <label key={index}>{error}</label>
            ))}
          </span>
        </div>
      )}
    </>
  );
};

export default ErrorMessages;
