import React from 'react'
import CategoryInsight from '../Components/Dashboard/CategoryInsight/CategoryInsight'
import { getlocalStorage } from '../Components/AuthUser/createSession';
import UserdemoCategoryInsight from './demo/userdemoCategoryInsight';

export default function CategoryIDashbord() {
  const categoryInsightAccess = getlocalStorage('categoryEmbeddedUrl');

  return (
    <>
      {categoryInsightAccess?<CategoryInsight />:
      <UserdemoCategoryInsight leftnavbar={true} logo={false} back={false} />}
    </>
  )
}
