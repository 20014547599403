
export const setValuesInLocalStorage = (user) => {
    const { 
        username, 
        embeddedUrl, 
        clientName, 
        email_id, 
        embeddedToken, 
        reportId, 
        categoryEmbeddedUrl, 
        categoryEmbeddedToken, 
        categoryReportId,
        role, 
        dashboard,
    } = user;

    // Only set localStorage for non-null and non-undefined values
    if (username) localStorage.setItem('username', username);
    if (clientName) localStorage.setItem('clientName', dashboard?.digitalshelf?.[0]?.client); 
    if (role) localStorage.setItem('role', role);
    if (email_id) localStorage.setItem('email', email_id);
    if (embeddedUrl) localStorage.setItem('embeddedUrl', embeddedUrl);
    if (embeddedToken) localStorage.setItem('embeddedToken', embeddedToken); // Store encrypted token
    if (reportId) localStorage.setItem('reportId', reportId);
    if (categoryEmbeddedUrl) localStorage.setItem('categoryEmbeddedUrl', categoryEmbeddedUrl);
    if (categoryEmbeddedToken) localStorage.setItem('categoryEmbeddedToken', categoryEmbeddedToken);
    if (categoryReportId) localStorage.setItem('categoryReportId', categoryReportId);
};
