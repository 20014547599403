import React, { useEffect, useState } from 'react'; 
import { getlocalStorage } from '../../AuthUser/createSession';
import Layout from '../../Layout/Layout'; 
import { E_KEY } from '../../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setMessage } from '../../../slices/message/messageSlice';
import { decryptData } from '../../authValidation/decryptFunc';
import { getUserData } from '../../../slices/auth/authSlice';
import PowerBIEmbedComponent from '../PowerBIEmbedComponent/PowerBIEmbedComponent';

const DigitalShelfDashboard = () => {
  const secretKey = E_KEY;
  const powerBiReportUrl = getlocalStorage('embeddedUrl');
  const report_id = getlocalStorage('reportId');
  const { getUserDataDetails } = useSelector((store) => store.auth);
  const [token, setToken] = useState(null);
  const [tokenValid, setTokenValid] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let encryptedData;

  const refreshToken = async () => {
    encryptedData = getlocalStorage('embeddedToken');
  }

  // Function to decrypt the token
  const decryptToken = async () => {
    console.log('checked');
    await refreshToken();
    try {
      if (encryptedData) {
        const decryptedToken = await decryptData(encryptedData, secretKey);
        setToken(decryptedToken);
        checkTokenValidity(decryptedToken);
      }
    } catch (error) {
      console.error('Error decrypting token:', error);
      handleTokenError('Error decrypting token');
    }
  };

  // Function to check token validity
  const checkTokenValidity = (decryptedToken) => {
    try {
      const decodedToken = JSON.parse(atob(decryptedToken.split('.')[1])); // Decode JWT token
      const expiryTime = decodedToken.exp * 1000; // Expiry time in milliseconds

      if (expiryTime < Date.now()) {
        dispatch(getUserData())
      } else {
        setTokenValid(true);
        // Token is still valid, set expiry check for the next expiry time
        const timeUntilExpiry = expiryTime - Date.now();
        setTimeout(() => checkTokenValidity(decryptedToken), timeUntilExpiry);
      }
    } catch (error) {
      console.error('Error parsing token:', error);
      setTokenValid(false);
    }
  };

  // Function to handle token errors
  const handleTokenError = (errorMessage) => {
    setTokenValid(false);
    dispatch(setMessage(errorMessage));
    navigate('/'); // Navigate to home or another route
  };

  // Decrypt the embeddedToken and check validity initially
  useEffect(() => {
    decryptToken();
  }, [getUserDataDetails]);

  return (
    <div >
      <Layout Leftnavbar={true}>
        <PowerBIEmbedComponent
          reportUrl={powerBiReportUrl}
          reportId={report_id}
          accessToken={token}
          tokenValid={tokenValid}
        />
      </Layout>
    </div>
  );
};

export default DigitalShelfDashboard;
