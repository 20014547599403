// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d_flex {
  display: flex;
}

.icon_leftnavbar {
  width: 2vw !important;
  height: 3vh !important;
}

.align_center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.align_center_text {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Quicksand";
}

.warningimg {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAaA;EACI,aAAA;AAZJ;;AAeA;EACI,qBAAA;EACA,sBAAA;AAZJ;;AAgBA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAbJ;;AAiBA;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,wBAnCQ;AAqBZ;;AAiBA;EACI,WAAA;EACA,YAAA;AAdJ","sourcesContent":["//global.scss\n$body-font: 'Quicksand';\n$font-Din: 'DIN';\n$body-font-sand: 'Quicksand';\n$black-Background: #000000;\n$blue-background: #cbe3f4;\n$red-color: #ff1a11;\n$light-blue-color: #F5FBFF;\n$light-shade-gray: #D4D4D4;\n$medium-shade-gray: #A3A3A3;\n$white-color: #FFFFFF;\n$Sky-Blue: #63a3ce;\n\n.d_flex {\n    display: flex;\n}\n\n.icon_leftnavbar{\n    width: 2vw !important;\n    height: 3vh !important;\n}\n\n \n.align_center {\n    display: flex;\n    justify-content: center; // Horizontally center the button\n    align-items: center; // Vertically center the button\n    height: 100vh;  \n}\n\n \n.align_center_text {\n    padding: 10px 20px;   \n    border: none;  \n    border-radius: 5px;  \n    font-size: 16px;  \n    font-family: $body-font; \n}\n\n.warningimg{\n    width: 24px;\n    height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
