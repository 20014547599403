import React from 'react'; 
import DigitalShelfDashboard from '../Components/Dashboard/Digitalshelf/DigitalshelfDashboard';

export default function UserDashboard() {
    return (
        <>
            <DigitalShelfDashboard />
        </>
    )
}
