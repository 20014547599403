import React from 'react';
import Layout from '../Components/Layout/signinPageLayout';
import SSOLogin from '../Components/SSO/SSOLogin';

export default function UserSignin() {
    return (
        <>
            <Layout>
                <SSOLogin />
            </Layout>
        </>
    )
}
