import { getAuthorization } from "../http-common";
import http from "../http-common/fileApi";

const SSOlogin = data => {
    return http.post("/api/validate-user", data, getAuthorization());
};

const  UserData = data => {
    return http.post("/api/get-user-data", data, getAuthorization());
};

const authService = { 
    UserData,
    SSOlogin
};

export default authService;