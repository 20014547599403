import React from 'react';
import DemoDigitalS from '../../Components/GetDemo/demoDigitalS/demodigitalS';

export default function UserdemoDigitalShelf({ logo, back }) {
  return (
    <div>
      <DemoDigitalS logo={logo} back={back} />
    </div>
  )
}
