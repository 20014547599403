import React, { useState } from 'react';
import Styles from '../getDemo.module.scss'; 
import forecasting from "../../../assets/images/Forecasting.png";
import { playb_hover, playbutton } from '../../../icon/IconConstants';
import { Button } from '../../Button/Button'; 
import LogoWithName from '../LogoWithName';

export default function DemoDigitalS({ logo, back }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <LogoWithName clickName={'digitalshelf'} Name={'Business DigitalShelf'} logo={logo} back={back} />
            <div className={Styles.container}>
                <div className={Styles.imgcontainer}>
                    <img className={Styles.forecastingImg} src={forecasting} alt="img"></img>
                    <div className={Styles.playbutton}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                        {isHovered ? <div dangerouslySetInnerHTML={{ __html: playb_hover }} /> :
                            <div dangerouslySetInnerHTML={{ __html: playbutton }} />}
                    </div>
                </div>
                <div className={Styles.headContainer}>
                    <div className={Styles.head_text}>
                        <span >DigitalShelf For Future Growth</span>
                    </div>
                    <div className={Styles.Dis_forec}>
                        <span  >
                            We help brands anticipate demand, optimize inventory management, allocate resources effectively, and plan marketing strategies. By providing foresight into potential opportunities and challenges, the Forecasting Module enables businesses to make proactive decisions and stay ahead in a dynamic marketplace.
                        </span>
                    </div>
                    <div className={Styles.buttn}>
                        <Button onClick={() => { }} name="Request for Opt-In" type="secondary" />
                    </div>
                </div>
            </div>
        </>
    );
}
