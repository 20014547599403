// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorMessages_d_flex__y-tFb {
  display: flex;
}

.errorMessages_icon_leftnavbar__yb\\+7P {
  width: 2vw !important;
  height: 3vh !important;
}

.errorMessages_align_center__gb\\+YS {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorMessages_align_center_text__aAefx {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Quicksand";
}

.errorMessages_warningimg__RX4WL {
  width: 24px;
  height: 24px;
}

.errorMessages_errorText__UwES4 {
  color: #ff1a11;
  font-family: "Quicksand";
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss","webpack://./src/Components/ErrorMessages/errorMessages.module.scss"],"names":[],"mappings":"AAaA;EACI,aAAA;ACZJ;;ADeA;EACI,qBAAA;EACA,sBAAA;ACZJ;;ADgBA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;ACbJ;;ADiBA;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,wBAnCQ;ACqBZ;;ADiBA;EACI,WAAA;EACA,YAAA;ACdJ;;AAtBA;EACI,cAAA;EACA,wBDNQ;ECOR,gBAAA;EACA,mBAAA;EACA,eAAA;AAyBJ","sourcesContent":["//global.scss\n$body-font: 'Quicksand';\n$font-Din: 'DIN';\n$body-font-sand: 'Quicksand';\n$black-Background: #000000;\n$blue-background: #cbe3f4;\n$red-color: #ff1a11;\n$light-blue-color: #F5FBFF;\n$light-shade-gray: #D4D4D4;\n$medium-shade-gray: #A3A3A3;\n$white-color: #FFFFFF;\n$Sky-Blue: #63a3ce;\n\n.d_flex {\n    display: flex;\n}\n\n.icon_leftnavbar{\n    width: 2vw !important;\n    height: 3vh !important;\n}\n\n \n.align_center {\n    display: flex;\n    justify-content: center; // Horizontally center the button\n    align-items: center; // Vertically center the button\n    height: 100vh;  \n}\n\n \n.align_center_text {\n    padding: 10px 20px;   \n    border: none;  \n    border-radius: 5px;  \n    font-size: 16px;  \n    font-family: $body-font; \n}\n\n.warningimg{\n    width: 24px;\n    height: 24px;\n}","@import '../../styles/global.scss';\n\n$error-red-color: $red-color;\n$font-error: $body-font;\n\n.errorText {\n    color: $error-red-color;\n    font-family: $font-error;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"d_flex": `errorMessages_d_flex__y-tFb`,
	"icon_leftnavbar": `errorMessages_icon_leftnavbar__yb+7P`,
	"align_center": `errorMessages_align_center__gb+YS`,
	"align_center_text": `errorMessages_align_center_text__aAefx`,
	"warningimg": `errorMessages_warningimg__RX4WL`,
	"errorText": `errorMessages_errorText__UwES4`
};
export default ___CSS_LOADER_EXPORT___;
