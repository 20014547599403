import { getAuthorization } from "../http-common";
import http from "../http-common/fileApi";

const ClientData = data => {
    return http.post("api/get-client-data", data, getAuthorization());
};

const clientDataService = { 
    ClientData,
};

export default clientDataService;