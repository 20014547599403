import React from 'react';
import GetDemo from '../Components/GetDemo/getDemo';

export default function DemoPage({ logo, back }) {
  return (
    <>
      <GetDemo logo={logo} back={back}/>
    </>
  )
}
