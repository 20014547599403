import React from 'react'
import Warning from '../assets/images/error.png';

export default function PageNotFound() {
  return (
    <>
      <div className='align_center'>
        <img className='warningimg' src={Warning} alt="logo" />
        <p className='align_center_text'>Page Not Found.</p>
      </div>
    </>
  )
}
