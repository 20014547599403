import Styles from './Header.module.scss';
import '../../styles/global.scss'; 
import { getlocalStorage, getUserName } from "../AuthUser/createSession";
import ABBOTTLogo from '../../assets/images/ABBOTT.png';
import AdminLogo from '../../assets/images/publicis-groupe-vector-logo (1).png';

export default function Header({ logo, name }) { 

    const U_name = getUserName();
    // const usernameInitials = U_name?.split(' ').map(name => name.charAt(0).toUpperCase()).join('');
    const User_clientName = getlocalStorage('clientName');
 

    return (
        <header className={Styles.container}>
            <div className="d_flex">
                {logo &&
                    <>
                        {User_clientName === 'ITC' && (
                            <img className={Styles.logo_img} src="https://th.bing.com/th/id/OIP.3ZOcTOp3s7xect4zOAHCRwHaHt?rs=1&pid=ImgDetMain" alt="logo" />
                        )}
                        {User_clientName === 'abbott' && (
                            <img className={Styles.logo_img} src={ABBOTTLogo} alt="logo" />
                        )}
                        {User_clientName === 'Admin' && (
                            <img className={Styles.logo_img} src={AdminLogo} alt="logo" />
                        )}
                        {!['ITC', 'abbott', 'Admin'].includes(User_clientName) && (
                            <img className={Styles.logo_img} src="" alt="logo" />
                        )}
                    </>
                }
                <span className={Styles.user_text}>
                &nbsp;&nbsp; {name ? name : 'Hello,'}&nbsp;
                    {!name && U_name}
                </span>
            </div>
        </header>
    );
}
