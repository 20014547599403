import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Styles from './Homepage.module.scss';
import { useNavigate } from 'react-router-dom';
import Digital_shelf from "../../assets/images/Digital_shelf.png";
import Fore_casting from "../../assets/images/Fore_casting.jpg";
import Download from "../../assets/images/C_I.png";
import Layout from '../Layout/Layout';
import '../../styles/global.scss';
import CustomCard from '../CustomCard/CustomCard';
import { getlocalStorage, removeItemLocal, setlocalStorage } from '../AuthUser/createSession';
import CustomDropdown from '../Dropdown/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../icon/IconConstants';
import { getClientData } from '../../slices/clientData/clientDataSlice';

export default function Homepage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { getUserDataDetails } = useSelector((store) => store.auth);
    const options = getlocalStorage('options');
    const dropDownOptions = options ? options?.split(',') : [];

    const { getClientDataDetails, clientblocking } = useSelector((store) => store.clientData);
    const role_Name = getlocalStorage('role');
    const isAdmin = role_Name === 'Admin';

    // const dropDownOptions = ['ITC', 'abbott', 'Admin'];
    const storedOption = localStorage.getItem('selectedOption');
    const [selectedOption, setSelectedOption] = useState(storedOption || dropDownOptions?.[0] || []);
    const [digitalShelfAccess, setDigitalShelfAccess] = useState(false);
    const [categoryInsightAccess, setCategoryInsightAccess] = useState(false);

    const handleButtonClick = (value) => {
        const routes = {
            'digitalshelf': '/digitalshelf',
            'Forecasting': '/forecasting',
            'categoryinsight': '/categoryinsight',
        };
        navigate(routes[value]);
    };

    const handleSelect = (selected) => {
        setSelectedOption(selected);
        // Apicall(selected);
        localStorage.setItem('selectedOption', selected); // Save the selected option to localStorage
    };

    // Set localStorage for digital shelf and category insight
    useEffect(() => {

        if (!isAdmin) return;

        // Update access based on localStorage for the digital shelf
        const digitalItcData = getClientDataDetails?.data?.digitalshelf;
        const categoryInsightData = getClientDataDetails?.data?.categoryinsights;

        // Set the digital shelf access
        if (digitalItcData && digitalItcData?.length !== 0) {
            const { embeddedUrl, embeddedToken, reportId, groupId } = digitalItcData;
            if (embeddedUrl) setlocalStorage('embeddedUrl', embeddedUrl);
            if (embeddedToken) setlocalStorage('embeddedToken', embeddedToken);
            if (reportId) setlocalStorage('reportId', reportId);
            // if (groupId) setlocalStorage('groupId', groupId);
            setDigitalShelfAccess(true); // Set access to true if the URL exists
        } else {
            ['embeddedUrl', 'embeddedToken', 'reportId'].forEach(removeItemLocal);
            setDigitalShelfAccess(false); // Set access to false if there's no digital shelf data
        }

        // Set the category insight access
        if (categoryInsightData && categoryInsightData?.length !== 0) {
            const { embeddedUrl, categoryEmbeddedToken, categoryReportId, groupId } = categoryInsightData;
            if (embeddedUrl) setlocalStorage('categoryEmbeddedUrl', embeddedUrl);
            if (categoryEmbeddedToken) setlocalStorage('categoryEmbeddedToken', categoryEmbeddedToken);
            if (categoryReportId) setlocalStorage('categoryReportId', categoryReportId);
            // if (groupId) setlocalStorage('categorygroupId', groupId);
            setCategoryInsightAccess(true); // Set access to true if the URL exists
        } else {
            ['categoryEmbeddedUrl', 'categoryEmbeddedToken', 'categoryReportId'].forEach(removeItemLocal);
            setCategoryInsightAccess(false); // Set access to false if no category insights
        }

    }, [getClientDataDetails, selectedOption]); // Run when selectedOption or getClientDataDetails changes

    useEffect(() => {
        if (!isAdmin) {
            const digitalShelfAccess = localStorage.getItem('embeddedUrl') ? true : false;
            const categoryInsightAccess = localStorage.getItem('categoryEmbeddedUrl') ? true : false;
            if (digitalShelfAccess) {
                setDigitalShelfAccess(true);
            } else {
                setDigitalShelfAccess(false); // Set access to false if there's no digital shelf data
            }
            if (categoryInsightAccess) {
                setCategoryInsightAccess(true);
            } else {
                setCategoryInsightAccess(false); // Set access to false if no category insights
            }
        }
    }, [selectedOption, getUserDataDetails])

    function Apicall(selected){
        if (isAdmin) {
            dispatch(getClientData({ client: selected }));
        }
    }

    useEffect(() => {
        if (isAdmin) {
            dispatch(getClientData({ client: selectedOption }));
        }
    }, [selectedOption]);

    return (
        <>
            <div className={Styles.container}>
                <Layout Leftnavbar={true}>
                    {isAdmin && clientblocking ?
                        <div className='align_center'>
                            <div className='align_center_text'>
                                <p >Loading...</p>
                                <div dangerouslySetInnerHTML={{ __html: Loader }} />
                            </div>
                        </div> : <>
                            <div className={Styles.headerContainer}>
                                <Header logo={true} />
                                {isAdmin && <div className={Styles.Dropdown}>
                                    <CustomDropdown options={dropDownOptions} onChange={handleSelect} defaultValue={selectedOption} />
                                </div>}
                            </div>
                            <div className={Styles.mUplopad_box}>
                                <div className={Styles.Digtal_box}>
                                    <CustomCard
                                        title={'Digital Shelf'}
                                        discrip_p={'Your destination for continuously tracking and analyzing the online presence and performance of products across different eCommerce Platforms. It involves monitoring product listings, pricing, availability, customer reviews, and competitor activity to ensure optimal visibility, positioning, and competitiveness in the online marketplace.'}
                                        buttonName={digitalShelfAccess ? 'View More' : 'Get Demo'}
                                        onClick={() => handleButtonClick('digitalshelf')}
                                        imgUrl={Digital_shelf}
                                        checked={digitalShelfAccess ? true : false}
                                    />
                                </div>
                                <div className={Styles.forecating}>
                                    <CustomCard
                                        title={'Forecasting'}
                                        discrip_p={'We help brands anticipate demand, optimize inventory management, allocate resources effectively, and plan marketing strategies. By providing foresight into potential opportunities and challenges, the Forecasting Module enables businesses to make proactive decisions and stay ahead in a dynamic marketplace'}
                                        buttonName={'Get Demo'}
                                        onClick={() => handleButtonClick('Forecasting')}
                                        imgUrl={Fore_casting}
                                    />
                                </div>
                            </div>
                            <div className={Styles.mUplopad_box}>
                                <div className={Styles.CategoryInsight}>
                                    <CustomCard
                                        title={'Category Insights'}
                                        discrip_p={'Enabling brands with actionable intelligence derived from analyzing data from multiple sources. These insights provide valuable perspectives on sales performance, customer behavior, market trends, and competitor activity. By leveraging data analytics and visualization tools, Business Insights enable informed decision-making and strategic planning to drive business growth and profitability.'}
                                        buttonName={categoryInsightAccess ? 'View More' : 'Get Demo'}
                                        onClick={() => handleButtonClick('categoryinsight')}
                                        imgUrl={Download}
                                        checked={categoryInsightAccess ? true : false}

                                    />
                                </div>
                                {/* <div className={Styles.download_report}>
                                    <CustomCard
                                        title={'Download Report'}
                                        discrip_p={'Your destination for scheduled & generated reports in CSV/PDF format.'}
                                        buttonName={'View More'}
                                        // onClick={handleButtonClick}
                                        imgUrl={Download_reports}
                                    />
                                </div> */}
                            </div>
                        </>}
                </Layout>
            </div>
        </>
    )
}
