import React from 'react';
import DemoForecasting from '../../Components/GetDemo/demoForecasting/Forecasting';

export default function Userdemoforecasting({ leftnavbar, logo, back }) {
    return (
        <>
            <DemoForecasting leftnavbar={leftnavbar} logo={logo} back={back} />
        </>
    )
}
