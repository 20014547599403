import { setlocalStorage } from "../Components/AuthUser/createSession"; 

let API_URL = process.env.REACT_APP_API_BASE_URL
let LOGIN_URL = process.env.REACT_APP_LOGIN_ENDPOINT

let ITC_DASHBOARD_DOWNLOAD_URL = process.env.REACT_APP_POWERBI_DOWNLOAD_ITC
let ABBOTT_DASHBOARD_DOWNLOAD_URL = process.env.REACT_APP_POWERBI_DOWNLOAD_ABBOTT
let ADMIN_DASHBOARD_DOWNLOAD_URL = process.env.REACT_APP_POWERBI_DOWNLOAD_ADMIN
let E_KEY = process.env.REACT_APP_SECRECT_K_P1 +"$"+process.env.REACT_APP_SECRECT_K_P2
let SSO_SIGNIN_URL = `${API_URL}${LOGIN_URL}`
let CATEORY_INSIGHT_DASHBOARD_DOWNLOAD_URL = process.env.REACT_APP_POWERBI_DOWNLOAD_CATEGORY_I

function CheckTokenStatusCode(statusCode) { 
    if (statusCode === "3006") { 
        setlocalStorage('checkToken', "0");
        window.location.href='/'
    }
}

export {
    API_URL,
    SSO_SIGNIN_URL,
    ITC_DASHBOARD_DOWNLOAD_URL, 
    ABBOTT_DASHBOARD_DOWNLOAD_URL, 
    ADMIN_DASHBOARD_DOWNLOAD_URL, 
    E_KEY,
    CATEORY_INSIGHT_DASHBOARD_DOWNLOAD_URL,
    CheckTokenStatusCode
}