import CryptoJS from 'crypto-js';

export function decryptData(encryptedData, encryptionKey) {
    // Decode the base64 encrypted data
    const ciphertext = CryptoJS.enc.Base64.parse(encryptedData);

    // Derive the key and IV from the encryption key
    const key = CryptoJS.enc.Utf8.parse(encryptionKey.substring(0, 32)); // First 32 characters as key
    const iv = CryptoJS.enc.Utf8.parse(encryptionKey.substring(0, 16)); // First 16 characters as IV

    // Decrypt using AES-256-CBC
    const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // Convert decrypted data to UTF-8 string
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

    return decryptedData;
}
